define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/available/layout',

  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'upx.modules/PaymentModule/models/GiftCard',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',

  'modules/shop.cash-register-retail/models/keyboard',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'upx.modules/PaymentModule/collections/GiftCard',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.cash-register-retail/views/popups/giftcardManagePopup',
  'modules/shop.cash-register-retail/views/popups/selectGiftCardForRefundPopup',
  'modules/shop.cash-register-retail/views/popups/loyaltyPointsPaymentPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/events/app/fullScreenLoader',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/popups/chooseOpenOrder',
  'modules/shop.cash-register-retail/views/popups/appendOrderItemsToOpenOrder',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.common/components/deviceConfig',

  'modules/shop.cash-register-retail/models/ccv/openCCVPinTransaction',
  'modules/shop.cash-register-retail/models/ccv/ccvPin',
  'modules/shop.cash-register-retail/views/popups/giftcardPopup',
], (
  $, _, Backbone, Template,
  PaymentMethodItemCollection, paymentMethodsSettingsModel, SelectedCustomerModel, GiftCardModel, LoyaltyProgramModel,
  KeyboardModel,
  CurrentOrderItemCollection, CashRegisterApi, GiftCardCollection, PaymentMethods,
  GiftcardManagePopupView, SelectGiftCardForRefundPopupView, LoyaltyPointsPaymentPopupView, MessagePopupView, FullScreenLoaderEvent, Locale,
  ChooseOpenOrderView, AppendItemsView, Upx, ShopPosModel, DeviceConfig,
  OpenCCVPinTransaction, CCVPin, GiftcardPopupView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'available-payment-methods',

  events: {
    'click [data-action="click"]': 'btnClicked',
  },

  modelEvents: {
    all: 'render',
  },

  regions: {
    popup: '[data-region=popup]',
  },

  paymentMethodsForRefund: [
    paymentMethodsSettingsModel.PIN_METHOD,
    paymentMethodsSettingsModel.CASH_METHOD,
    paymentMethodsSettingsModel.GIFTCARD_METHOD,
    paymentMethodsSettingsModel.OTHER_METHOD,
  ],

  initialize({
    collection = null,
    customerModel = null,
    totalPriceWt = null,
    iclModel = null,
    orderModel = null,
    loyaltyProgramModel = null,
  }) {
    this.collection = collection || PaymentMethodItemCollection;
    this.customerModel = customerModel || SelectedCustomerModel;
    this.loyaltyProgramModel = loyaltyProgramModel || LoyaltyProgramModel;
    this.totalPriceWt = totalPriceWt || CurrentOrderItemCollection.getTotalPriceWt();
    this.isNegative = parseFloat(this.totalPriceWt) < 0;
    this.iclModel = iclModel;
    this.orderModel = orderModel;
  },

  onShow() {
    this.collection.on('all', this.render, this);
    this.customerModel.on('all', this.render, this);
    if (this.iclModel) {
      this.iclModel.on('change', this.render, this);
    }
  },

  onDestroy() {
    this.collection.off('all', this.render, this);
    this.customerModel.off('all', this.render, this);
    if (this.iclModel) {
      this.iclModel.off('change', this.render, this);
    }
  },

  removeGitfcardPayments() {
    const modelsToRemove = [];
    this.collection.each((model) => {
      const id = model.get('id');
      if (
        id.startsWith(this.collection.GIFTCARD_METHOD)
                    || id.startsWith(this.collection.EXTERNAL_GIFTCARD_METHOD)
      ) {
        modelsToRemove.push(model);
      }
    });
    this.collection.remove(modelsToRemove);
  },

  togglePaymentMethod(id) {
    const model = this.collection.get(id);
    if (model) {
      // disabling method
      this.removePayment(model);

      if (id === paymentMethodsSettingsModel.PIN_METHOD) {
        // remove pin extra as well
        const pinExtraMethod = paymentMethodsSettingsModel.PIN_EXTRA_METHOD;
        const pinExtraModel = this.collection.get(pinExtraMethod);
        if (pinExtraModel) {
          this.togglePaymentMethod(pinExtraMethod);
        }
      }
    } else if (!this.collection.existsById(id)) {
      // enabling method
      this.addPayment(id);

      if (id === paymentMethodsSettingsModel.PIN_EXTRA_METHOD) {
        // add pin add well when extra is seletect
        const pinMethod = paymentMethodsSettingsModel.PIN_METHOD;
        const pinModel = this.collection.get(pinMethod);
        if (!pinModel) {
          this.togglePaymentMethod(pinMethod);
        }
      }

      if (id === paymentMethodsSettingsModel.PIN_METHOD) {
        // disable qr when pin is selected
        const qrMethod = paymentMethodsSettingsModel.QR_CODE_METHOD;
        const qrModel = this.collection.get(qrMethod);
        if (qrModel) {
          this.togglePaymentMethod(qrMethod);
        }
        if (this.isNegative) {
          this.toggleOtherPaymentMethodsForRefund(paymentMethodsSettingsModel.PIN_METHOD);
        }
      }
      if (id === paymentMethodsSettingsModel.QR_CODE_METHOD) {
        // disable pin when qr is selected
        const pinMethod = paymentMethodsSettingsModel.PIN_METHOD;
        const pinModel = this.collection.get(pinMethod);
        if (pinModel) {
          this.togglePaymentMethod(pinMethod);
        }
      }

      if (id === paymentMethodsSettingsModel.CASH_METHOD) {
        if (this.isNegative) {
          this.toggleOtherPaymentMethodsForRefund(paymentMethodsSettingsModel.CASH_METHOD);
        }
      }

      if (id === paymentMethodsSettingsModel.OTHER_METHOD) {
        if (this.isNegative) {
          this.toggleOtherPaymentMethodsForRefund(paymentMethodsSettingsModel.OTHER_METHOD);
        }
      }
    }
  },

  toggleOtherPaymentMethodsForRefund(except_method) {
    const filtered = this.paymentMethodsForRefund.filter((method) => method !== except_method);

    filtered.forEach((method) => {
      if (method === paymentMethodsSettingsModel.GIFTCARD_METHOD) {
        const result = this.collection.where({ is_giftcard: true });
        if (result.length > 0) {
          result.forEach((model) => {
            this.togglePaymentMethod(model);
          });
        }
      } else {
        const model = this.collection.get(method);
        if (model) {
          this.togglePaymentMethod(model);
        }
      }
    });
  },

  removePayment(model) {
    CashRegisterApi.logAction('PAYMENT_REMOVED_FROM_ORDER', {
      model: model.toJSON(),
    });
    // If the collection has localStorage,
    // it means that the model can call destroy on itself without throwing errors.
    if (this.collection.localStorage) {
      model.destroy();
    } else {
      this.collection.remove(model);
    }
  },

  addPayment(id) {
    const paymentModel = this.options.paymentProviderMethodCollection.getModelByAlias(id);
    let data = {
      totalPriceWt: this.totalPriceWt,
    };
    if (paymentModel) {
      data = {
        ...data,
        provider_id: paymentModel.get('provider_id'),
        provider_method_id: paymentModel.get('id'),
      };
    }
    const saveModel = this.collection.addMethodById(id, data);
    CashRegisterApi.logAction('PAYMENT_ADDED_FROM_ORDER', {
      model: saveModel.toJSON(),
    });
  },

  showError(error) {
    const errorView = new MessagePopupView();
    errorView.open(
      error,
    );
  },
  processAppendToOrder(order) {
    if (order.get('table_id')) {
      this.showError(Locale.translate('table_order_cannot_be_added_to_open_order'));
      return;
    }
    if (order.get('repair_id')) {
      this.showError(Locale.translate('repair_cannot_be_added_to_open_order'));
      return;
    }
    let hasGiftMemberCard = false;
    CurrentOrderItemCollection.each((model) => {
      const type = model.get('type');
      if (
        type === CurrentOrderItemCollection.TYPE_GIFTCARD
          || type === CurrentOrderItemCollection.TYPE_TOP_UP
          || type === CurrentOrderItemCollection.TYPE_MEMBERCARD_GIFTCARD
          || type === CurrentOrderItemCollection.TYPE_MEMBERCARD
      ) {
        hasGiftMemberCard = hasGiftMemberCard || true;
      }
    });
    if (hasGiftMemberCard) {
      this.showError(Locale.translate('order_has_a_membercard_or_giftcard_and_cannot_be_added_to_open_order'));
      return;
    }
    const appendView = new AppendItemsView({
      appendToOrder: order,
      order: this.orderModel,
    });
    appendView.open().then(
      () => {
        const def = new $.Deferred();
        const event = new FullScreenLoaderEvent({
          deferred: def,
          title: Locale.translate('adding_items_to_order'),
        });
        event.trigger();
        const order_items = this.orderModel.get('order_items').map(
          (item) => {
            const resultItem = {};
            const allowedFields = [
              'sku', 'name', 'description',
              'ppu', 'ppu_wt', 'before_discount_ppu', 'before_discount_ppu_wt',
              'quantity',
              'extra',
              'product_id', 'shop_product_id', 'tax_rate_id',
            ];
            allowedFields.forEach((name) => {
              if (name in item) {
                resultItem[name] = item[name];
              }
            });
            resultItem.subitems = [];
            const subitems = item.subitems || item.sub_items || [];
            if (subitems.length) {
              subitems.forEach((subitem) => {
                const resultSubitem = {};
                allowedFields.forEach((name) => {
                  if (name in subitem) {
                    resultSubitem[name] = subitem[name];
                  }
                });
                resultItem.subitems.push(resultSubitem);
              });
            }
            return resultItem;
          },
        );
        Upx.call('ShopModule', 'newOrderItemsWithPickUpInStoreOrderShipment',
          {
            fields: {
              order_items,
              order_id: order.get('id'),
              force_negative_stock_if_missing: 'true',
            },
          }).then(
          (shipment_id) => {
            CashRegisterApi.logAction('APPEND_TO_ORDER_SUCCESS', {
              order_items: this.orderModel.get('order_items'),
              order_id: order.get('id'),
              shipment_id,
            });

            def.resolve();

            PaymentMethodItemCollection.clear();
            SelectedCustomerModel.unload();
            CurrentOrderItemCollection.clear();

            // this will cancel the current order
            Backbone.history.navigate('checkout', { trigger: true });
          },
          () => {
            def.reject();
            this.showError(Locale.translate('failed_to_add_items_to_existing_order'));
          },
        );
      },
      // cancel not interesting, nothing happes
    );
  },

  togglePayLater() {
    const id = this.collection.PAYLATER_METHOD;
    const model = this.collection.get(id);
    if (model) {
      this.removePayment(model);
    } else if (!this.collection.existsById(id)) {
      if (this.orderModel) {
        if (ShopPosModel.get('mode') === DeviceConfig.MODE_Hospitality) {
          // horeca required direct printing which is not supported
          this.addPayment(id);
        } else {
          const view = new ChooseOpenOrderView();
          view.open(
            this.customerModel.get('id'),
            this.orderModel,
            this.iclModel && this.iclModel.get('selected'),
          ).then(
            (order) => {
              if (order.get('id') !== this.orderModel.get('id')) {
                this.processAppendToOrder(order);
              } else {
                this.addPayment(id);
              }
            },
          );
        }
      } else {
        console.warn('togglePayLater: No this.orderModel set, cannot detect current order');
        this.addPayment(id);
      }
    }
  },

  toggleLoyaltyPoints() {
    const self = this;
    const id = this.collection.LOYALTY_POINTS_METHOD;
    const model = this.collection.get(id);
    if (model) {
      this.removePayment(model);
    } else if (!this.collection.existsById(id)) {
      const view = new LoyaltyPointsPaymentPopupView({
        paymentMethodCollection: this.collection,
        loyaltyProgramModel: this.loyaltyProgramModel,
        customerModel: this.customerModel,
      });
      const viewDef = new $.Deferred();
      view.open(viewDef);

      viewDef.then(({
        ppuWt, maxAmount, loyaltyPointsSpent, loyaltyPointsOriginalBalance,
      }) => {
        const loyaltyProgramId = self.loyaltyProgramModel.get('id');
        const payment = this.collection.addLoyaltyPointMethod(
          ppuWt,
          maxAmount,
          loyaltyPointsSpent,
          loyaltyPointsOriginalBalance,
          loyaltyProgramId,
        );

        CashRegisterApi.logAction('PAYMENT_ADDED_FROM_ORDER', {
          model: payment.toJSON(),
        });
      });
    }
  },

  btnClicked(e) {
    // increases the touch speed
    e.stopPropagation();
    e.preventDefault();

    const $el = $(e.currentTarget);
    $el.blur();

    KeyboardModel.resetMode();

    const id = $el.data('id');
    if (id === this.collection.GIFTCARD_METHOD) {
      this.checkCustomerGiftcards()
        .then((giftCardCollection) => {
          const view = this.getGiftCardMethodPopupView(giftCardCollection);
          const viewDef = new $.Deferred();

          view.open(viewDef);

          viewDef.then((data) => {
            this.resolveGiftCardPopup(data);
          }, (closed) => {
            // If a new popup was opened from the previous one
            if (closed && closed.newDef) {
              closed.newDef.then((data) => {
                this.resolveGiftCardPopup(data);
              });
            }
          });
        });
    } else if (id === this.collection.PAYLATER_METHOD) {
      this.togglePayLater();
    } else if (id === this.collection.LOYALTY_POINTS_METHOD) {
      this.toggleLoyaltyPoints();
    } else if (id === this.collection.PIN_METHOD && OpenCCVPinTransaction.hasOpenTransaction()) {
      CCVPin.openOpenTransactionPopup();
    } else {
      this.togglePaymentMethod(id);
    }
  },

  handleNewGiftCardPopup(data) {
    const def = new $.Deferred();
    const event = new FullScreenLoaderEvent({
      deferred: def,
      title: Locale.translate('checking_if_gift_card_is_available'),
    });
    event.trigger();

    const { code } = data;
    const giftCardModel = new GiftCardModel();
    giftCardModel.find({ code }).then(
      () => {
        def.reject();
        const errorView = new MessagePopupView();
        errorView.open(Locale.translate('payment_card_already_sold'));
      },
      () => {
        def.resolve();
        const model = new Backbone.Model(data);
        model.set('type', this.collection.GIFTCARD_METHOD);
        model.set('relation_data_id', this.customerModel.get('id'));
        this.addGiftCardPopupPaymentToCollection(model);
      },
    );
  },

  handleExistingGiftCardPopup(data) {
    const { collection } = data;
    collection.forEach((model) => this.addGiftCardPopupPaymentToCollection(model));
  },

  resolveGiftCardPopup(data) {
    this.removeGitfcardPayments();

    const { create_new } = data;
    if (create_new) {
      this.handleNewGiftCardPopup(data);
    } else {
      this.handleExistingGiftCardPopup(data);
    }
  },

  getGiftCardMethodPopupView(giftCardCollection) {
    let view;
    if (this.isNegative) {
      if (giftCardCollection && giftCardCollection.length > 0) {
        view = new SelectGiftCardForRefundPopupView({
          giftCardCollection,
          totalPriceWt: this.totalPriceWt,
        });
      } else {
        view = new GiftcardPopupView({
          initialBalance: this.totalPriceWt * -1,
        });
      }
    } else {
      view = new GiftcardManagePopupView({
        collection: this.collection,
      });
    }

    return view;
  },

  addGiftCardPopupPaymentToCollection(model) {
    let payment = null;
    if (model.get('type') === this.collection.EXTERNAL_GIFTCARD_METHOD) {
      payment = this.collection.addExternalGiftCardMethod(
        model.get('code'),
        model.get('balance'),
        model.get('provider_method.title'),
        model.get('provider_method.image_url'),
        model.get('provider_method.id'),
        model.get('provider_method.provider_id'),
        model.get('pin'),
        model.get('provider_method.max_amount'),
        { save: this.saveMethods },
      );
    } else if (model.get('type') === this.collection.GIFTCARD_METHOD) {
      payment = this.collection.addGiftCardMethod(
        model.get('code'),
        model.get('balance'),
        model.get('gift_card_id'),
        {
          totalPriceWt: this.totalPriceWt,
        },
      );
    } else {
      console.error(`Unknown giftcard type ${model.get('type')}`);
    }
    if (payment) {
      // we add the original data,
      // so it can be retrieved in the giftcard popup
      payment.set('original_data', model.toJSON());
      if (this.saveMethods) payment.save();
      CashRegisterApi.logAction('PAYMENT_ADDED_FROM_ORDER', {
        model: payment.toJSON(),
      });

      if (this.isNegative) {
        this.toggleOtherPaymentMethodsForRefund(paymentMethodsSettingsModel.GIFTCARD_METHOD);
      }
    }
  },

  getCollection() {
    const hasCustomer = this.customerModel.has('id');
    const hasInvoicePayment = !!this.collection.get(this.collection.INVOICE_METHOD);
    const hasOnOrderPayment = !!this.collection.get(this.collection.PAYLATER_METHOD);
    const hasPinExtraPayment = !!this.collection.get(this.collection.PIN_EXTRA_METHOD);
    const orderTotalWt = parseFloat(this.totalPriceWt);
    const prepaymentMethods = this.collection.getPrePaymentMethods();
    const payLaterMethods = this.collection.getPayLaterMethods();
    return paymentMethodsSettingsModel.getEnabledPaymentMethodCollection()
      .map((model) => {
        const modelData = model.toJSON();
        const { id, requires_customer } = modelData;

        // Check for the initial state
        let disabled = requires_customer;
        if (requires_customer && hasCustomer) {
          disabled = false;
        }

        // On order / invoice allow to pre-pay with certain methods
        if ((hasOnOrderPayment || hasInvoicePayment) && prepaymentMethods.indexOf(id) === -1) {
          disabled = true;
        } else if (
          (
            hasOnOrderPayment
            || hasInvoicePayment
            || hasPinExtraPayment
          )
          && payLaterMethods.indexOf(id) !== -1
        ) {
          disabled = true;
        }

        // Check if a giftcard payment is added
        let selected = false;
        if (id === this.collection.GIFTCARD_METHOD) {
          selected = this.collection.filter((paymentModel) => {
            const methodId = paymentModel.get('id');
            return methodId.startsWith(this.collection.EXTERNAL_GIFTCARD_METHOD)
                                || methodId.startsWith(this.collection.GIFTCARD_METHOD);
          }).length > 0;
        } else if (this.collection.get(id)) {
          selected = true;
          disabled = false;
        }

        if (id === this.collection.LOYALTY_POINTS_METHOD) {
          if (this.customerModel) {
            const loyaltyPoints = this.customerModel.get('loyalty_customer.point_total') || 0;
            disabled = loyaltyPoints <= 0;
          }

          // Using points as payment is not allowed
          if (!this.loyaltyProgramModel.get('allow_points_as_payment')) {
            disabled = true;
          }
        }

        // disable do the case of negative amount
        if (this.isNegative) {
          if (this.paymentMethodsForRefund.includes(id)) {
            disabled = false; // cash return to hand
          } else if (
            id === this.collection.PIN_METHOD
              && paymentMethodsSettingsModel.pinReturnEnabled()
          ) {
            disabled = false; // refund by pin to bank account
          } else {
            disabled = true;
          }
        }

        return {
          id,
          requiresCustomer: model.get('requires_customer'),
          disabled,
          selected,
          icon: paymentMethodsSettingsModel.getIconByMethod(id),
          name: paymentMethodsSettingsModel.getNameByMethod(id),
        };
      });
  },

  serializeData() {
    return {
      collection: this.getCollection(),
      iclModel: this.iclModel ? this.iclModel.toJSON() : null,
    };
  },

  /**
   * Check if the customer has any giftcards.
   * If so, we add them
   */
  checkCustomerGiftcards() {
    const def = new $.Deferred();
    if (this.customerModel.has('id')) {
      const event = new FullScreenLoaderEvent({
        deferred: def,
        title: Locale.translate('checking_if_customer_has_gift_cards_payment_cards'),
      });
      event.trigger();

      const giftCardCollection = new GiftCardCollection();
      const giftCardParameters = {
        start: 0,
        limit: 0,
        sort: [{
          name: 'date_created',
          dir: 'asc',
        }],
        filters: [
          {
            name: 'balance__>',
            val: '0',
          },
          {
            name: 'in_stock__=',
            val: '0',
          },
          {
            name: 'is_anonymous__=',
            val: false,
          },
          {
            name: 'relation_data_id__=',
            val: this.customerModel.get('id'),
          },
        ],
      };

      giftCardCollection.fetch({ params: giftCardParameters })
        .then(() => {
          giftCardCollection.each((model) => {
            model.set({
              type: PaymentMethods.GIFTCARD_METHOD,
              gift_card_id: model.get('id'),
              id: `${PaymentMethods.GIFTCARD_METHOD}:${model.get('code')}`,
            });
            if (!this.isNegative) {
              const payment = this.collection.addGiftCardMethod(
                model.get('code'),
                model.get('balance'),
                model.get('id'),
              );
              payment.set('original_data', model.toJSON());
            }
          });
          def.resolve(giftCardCollection);
        }, def.reject);
    } else {
      def.resolve(null);
    }

    return def;
  },

}));
