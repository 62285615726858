define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentScreens/invoice/itemTotals',
  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone, Template,
  HistoryBreadcrumb,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  initialize({ model, invoiceStatModel, customerModel }) {
    this.customerModel = customerModel;
    this.invoiceStatModel = invoiceStatModel;
    this.model = model;
  },

  events: {
    'click [data-action=back]': 'backClicked',
  },

  backClicked() {
    HistoryBreadcrumb.goBack();
  },

  serializeData() {
    return {
      total_outstanding_wt: this.invoiceStatModel.get('value_outstanding_wt'),
      invoice_outstanding_wt: this.model.get('value_outstanding_wt'),
      currency_iso3: this.invoiceStatModel.get('currency_iso3'),
    };
  },

}));
