define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/list/cellPpu',

  'modules/common/components/currency',

  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',
], (
  $, _, Backbone, Template,
  Currency,
  WithKeyboardBehaviour, LoyaltyProgramModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  events: {
    click: 'onClick',
  },

  tagName: 'td',

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
    },
  },

  attributes() {
    return {
      'data-payment-method': this.options.paymentModel.get('id'),
    };
  },

  className() {
    return `${this.model.get('id')} text-right`;
  },

  initialize({
    paymentMethodCollection, paymentModel, shopManualRefundPopup, loyaltyProgramModel = null,
  }) {
    this.paymentMethodCollection = paymentMethodCollection;
    this.shopManualRefundPopup = shopManualRefundPopup;
    this.loyaltyProgramModel = loyaltyProgramModel || LoyaltyProgramModel;

    this.paymentModel = paymentModel;
    this.previousPpu = this.paymentModel.get('ppu_wt');

    this.keyboardMode = this.keyboardModel.MODE_PAYMENT_VALUE;
  },

  onClick: _.debounce(function () {
    if (this.isLocked) {
      this.shopManualRefundPopup();
    }
  }),

  renderLockMode() {
    this.isLocked = !!this.paymentModel.get('lockedAlreadyPaid');
    if (this.isLocked) {
      this.$el.addClass('refund-locked');
      this.isEditable = false;
    } else {
      this.$el.removeClass('refund-locked');
      this.isEditable = true;
    }
  },

  onShow() {
    this.paymentModel.on('change:ppu_wt', this.ppuChanged, this);
    this.paymentModel.on('change:lockedAlreadyPaid', this.renderLockMode, this);
    this.renderLockMode();
  },

  onDestroy() {
    this.paymentModel.off('change:ppu_wt', this.ppuChanged, this);
    this.paymentModel.off('change:lockedAlreadyPaid', this.renderLockMode, this);
  },

  ppuChanged() {
    if (!this.isMyKeyboardMode()) {
      this.previousPpu = this.paymentModel.get('ppu_wt');
    }
    this.render();
  },

  onModeSelected() {
    this.valueWasSet = false;
  },

  onModeDeselected() {
    if (!this.valueWasSet) {
      this.onCancel();
    }
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      let currentPrice = this.paymentModel.get('ppu_wt');
      if (this.firstInput) {
        currentPrice = '0.00';
        this.firstInput = false;
      }

      let newPrice = this.handleInputOnPrice(currentPrice, key);
      // limit the price to the giftcard value
      const id = this.paymentModel.get('id');
      if (this.paymentModel.get('max_amount') !== null
          && parseFloat(newPrice) > parseFloat(this.paymentModel.get('max_amount'))
      ) {
        newPrice = this.paymentModel.get('max_amount');
      }

      if (
        (
          id.startsWith(this.paymentMethodCollection.GIFTCARD_METHOD)
          || id.startsWith(this.paymentMethodCollection.EXTERNAL_GIFTCARD_METHOD)
        )
        && parseFloat(newPrice) > parseFloat(this.paymentModel.get('balance'))
      ) {
        newPrice = this.paymentModel.get('balance');
      }

      if (parseFloat(newPrice) > 100000.00) {
        newPrice = '100000.00';
      }

      // Loyalty points payment should only set left to pay
      if (id === this.paymentMethodCollection.LOYALTY_POINTS_METHOD) {
        if (parseFloat(this.paymentMethodCollection.getLeftToPayWt((model) => model.get('id') !== id)) < parseFloat(newPrice)) {
          newPrice = this.paymentMethodCollection.getLeftToPayWt((model) => model.get('id') !== id);
        }
        this.paymentModel.set('loyaltyPointsSpent', this.loyaltyProgramModel.getSpentPointsValueOfFloat(
          parseFloat(newPrice),
        ));
      }

      this.valueWasSet = true;
      this.paymentModel.set({
        ppu_wt: newPrice,
        setManually: true,
      });
    }
  },

  onBackspacePress() {
    // Mark as changed
    this.firstInput = false;
    // Update the price
    const newPrice = this.removeDigitFromPrice(this.paymentModel.get('ppu_wt'));
    this.paymentModel.set({
      ppu_wt: newPrice,
      setManually: true,
    });
  },

  onConfirmation() {
    this.previousPpu = this.paymentModel.get('ppu_wt');
    this.paymentModel.setPpuWt(
      this.paymentModel.get('ppu_wt'),
    );
  },

  onCancel() {
    this.paymentModel.set({
      ppu_wt: this.previousPpu,
    });
  },

  serializeData() {
    const data = this.paymentModel.toJSON();
    data.ppu_wt_formatted = Currency.Math.format('EUR', data.ppu_wt);
    return data;
  },

}));
