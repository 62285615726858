define([
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/payments/processingItem',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/components/timer',
  'modules/shop.common/components/crypto',
], (
  _, Backbone,
  ItemView, Locale, PaymentMethodsSettingModel, ProfilingTimer, Crypto,
) => {
  // private model for processing
  const EventModel = Backbone.Model.extend({
    defaults: {
      success: null,
      description: null,
      status: '...',

    },

    setDescription(description) {
      this.set({
        description,
      });
    },

    setPaymentValue(value) {
      this.set({
        value,
      });
    },

    setProcessingStatus() {
      this.setStatus(Locale.translate('processing_dot'));
      this.set('errorDescription', null);
    },

    setStatus(status) {
      this.set({
        status,
      });
    },

    setQrImageUrl(qrUrl, qrImageUrl) {
      this.set({
        qrUrl,
        qrImageUrl,
      });
    },

    showPinInput() {
      this.set({
        showPin: true,
      });
    },

    hidePinInput() {
      this.set({
        showPin: false,
      });
    },

    setQrApps(qrIsIn3, qrIsIdeal, qrIsBilink, qrIsBancontact) {
      this.set({
        qrIsIn3,
        qrIsIdeal,
        qrIsBilink,
        qrIsBancontact,
      });
    },

    setQrStatus(qrStatus, qrOverlayImageUrl = null) {
      this.set({
        qrStatus,
        qrOverlayImageUrl,
      });
    },

    setQrFinalStatus(qrFinalSuccess, qrFinalStatus, qrMethod = null) {
      this.set({
        qrFinalSuccess,
        qrFinalStatus,
        qrMethod,
      });
    },

    setRetryStatus(i, n, details) {
      let message = Locale.translate('retrying_{0}{1}', [i, n]);
      if (details) {
        message += `\n${details}`;
      }
      this.setStatus(message);
    },

    success() {
      this.set({
        status: Locale.translate('success'),
        success: true,
        endTime: (new Date()).getTime(),
      });
      this.reportTime();
    },

    error(error) {
      const statusText = Locale.translate('error');
      let errorDescription = null;
      if (typeof error === 'string') {
        // when string it`s set manually,
        // when object most of the time some error from backend
        errorDescription = error;
      }
      this.set({
        status: statusText,
        success: false,
        error,
        errorDescription,
        endTime: (new Date()).getTime(),
      });
      this.reportTime();
    },

    reportTime() {
      ProfilingTimer.postResult(
        this.get('success'),
        `ORDER-${this.get('id')}`,
        {}, {},
        this.get('endTime'),
        this.get('startTime'),
      );
    },

    def(def) {
      return def.then(
        () => this.success(),
        (error) => this.error(error),
      );
    },

  });

  const TYPE_NEW_ORDER = 'type-new-order';
  const TYPE_OUTSTANDING_INVOICE = 'type-oustanding-invoice';
  const TYPE_OUTSTANDING_ORDER = 'type-oustanding-order';

  const CollectionView = Backbone.Marionette.CollectionView.extend({
    childView: ItemView,
    tagName: 'table',

    TYPE_NEW_ORDER,
    TYPE_OUTSTANDING_INVOICE,
    TYPE_OUTSTANDING_ORDER,

    initialize({
      collection = new Backbone.Collection(),
      number = null,
      type = null,
    }) {
      this.collection = collection;
      this.number = number;
      this.type = type;
    },

    getEventById(type, id, iteration, unique = false) {
      let modelId = type;
      if (unique) {
        modelId = Crypto.shortid();
      }
      if (id) {
        modelId += `|${id}`;
      }
      if (iteration > 1) {
        modelId += `|${iteration}`;
      }
      let model = this.collection.get(modelId);
      if (!model) {
        model = this.collection.add(new EventModel({
          id: modelId,
          title: this.getEventTitle(type, id, iteration),
          startTime: (new Date()).getTime(),
        }));
      }
      return model;
    },

    getEventTitle(type, id, iteration) {
      let title = '';
      if (type === 'full') {
        title = this.getTitle();
      } else if (type === 'drawer') {
        title = Locale.translate('open_cash_register_drawer');
      } else if (type === 'payment') {
        title = Locale.translate('payment_{0}', PaymentMethodsSettingModel.getNameByMethod(id));
      } else if (type === 'payment-attach') {
        title = Locale.translate('synchronizing_payments');
      } else if (type === 'shipping') {
        title = Locale.translate('handling_stock_changes');
      } else if (type === 'giftcard') {
        title = Locale.translate('creating_gift_card');
      } else if (type === 'membercard') {
        title = Locale.translate('creating_customer_card');
      } else if (type === 'error') {
        title = Locale.translate('an_error_occurred');
      } else if (type === 'confirm') {
        switch (this.type) {
          case this.TYPE_OUTSTANDING_INVOICE:
            title = Locale.translate('confirming_invoice');
            break;
          case this.TYPE_NEW_ORDER:
          default:
            title = Locale.translate('confirming_order');
        }
      } else if (type === 'refund') {
        title = Locale.translate('refunding_products');
      } else if (type === 'signing') {
        title = Locale.translate('cryptographically_signing_the_receipt');
      } else if (type === 'printing') {
        title = Locale.translate('printing_kitchen_receipts');
      } else {
        console.error(`Missing payment processing title for ${type}`);
      }
      if (iteration > 1) {
        title += ` #${iteration}`;
      }
      return title;
    },

    error(errorMessage) {
      return this.getEventById('error').error(errorMessage);
    },

    drawerOpen() {
      return this.getEventById('drawer');
    },

    payment(alias, model, iteration, unique = false) {
      const log = this.getEventById('payment', alias, iteration, unique);
      log.setDescription(model.get('description'));
      if (model.get('ppu_wt')) {
        // native model
        log.setPaymentValue(model.get('ppu_wt'));
      } else if (model.get('amount')) {
        // payment model
        log.setPaymentValue(model.get('amount'));
      } if (model.get('refund_amount')) {
        // payment model
        log.setPaymentValue(model.get('refund_amount'));
      }
      const copyAttrs = [
        'code',
        'cashRefunded',
        'giftCardRefunded',
        'cashReceived',
        'changeReturned',
        'loyaltyPointsSpent',
        'loyaltyPointsOriginalBalance',
      ];
      _.each(copyAttrs, (attr) => {
        if (model.has(attr)) {
          log.set(attr, model.get(attr));
        }
      });
      return log;
    },

    full(model) {
      const log = this.getEventById('full');
      log.setProcessingStatus();
      if (this.type === this.TYPE_NEW_ORDER) {
        log.setPaymentValue(model.get('value_wt'));
        log.setDescription(Locale.translate('amount_of_articles_{0}', model.get('item_no')));
      }

      if (this.type === this.TYPE_OUTSTANDING_INVOICE) {
        log.setPaymentValue(model.get('value_outstanding_wt'));
      }
      return log;
    },

    stopAllTimes() {
      this.collection.each((event) => {
        const end = event.get('endTime');
        if (!end) {
          console.warn('Force stopped timer', event.toJSON());
          event.success();
        }
      });
    },

    printing() {
      const log = this.getEventById('printing');
      return log;
    },

    signing() {
      const log = this.getEventById('signing');
      return log;
    },

    shipping() {
      const log = this.getEventById('shipping');
      return log;
    },

    giftcard(card) {
      const log = this.getEventById('giftcard');
      return log;
    },

    membercard(card) {
      const log = this.getEventById('membercard');
      return log;
    },

    confirm() {
      const log = this.getEventById('confirm');
      log.setProcessingStatus();
      return log;
    },

    refund() {
      const log = this.getEventById('refund');
      return log;
    },

    attachPayments() {
      const log = this.getEventById('payment-attach');
      log.setProcessingStatus();
      return log;
    },

    getTitle() {
      switch (this.type) {
        case this.TYPE_OUTSTANDING_INVOICE:
          return Locale.translate('invoice_{0}', this.number);
        case this.TYPE_NEW_ORDER:
        default:
          return Locale.translate('order_{0}', this.number);
      }
    },

  });

  CollectionView.TYPE_NEW_ORDER = TYPE_NEW_ORDER;
  CollectionView.TYPE_OUTSTANDING_INVOICE = TYPE_OUTSTANDING_INVOICE;

  return CollectionView;
});
