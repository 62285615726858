define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/list/cellPpu',

  'modules/common/components/currency',
], (
  $, _, Backbone, Template,
  Currency,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'td',

  attributes() {
    return {
      'data-payment-method': this.options.paymentModel.get('id'),
    };
  },

  className() {
    return `${this.model.get('id')} text-right`;
  },

  initialize({ paymentModel }) {
    this.paymentModel = paymentModel;
  },

  serializeData() {
    const ppu_wt_formatted = Currency.Math.format(
      'EUR',
      Currency.toCurrency(this.paymentModel.get('refund_amount')),
    );
    return {
      ppu_wt_formatted,
    };
  },

}));
