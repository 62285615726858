define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone,
  Locale, KeyboardModel,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'button',

  className: 'btn btn-success btn-block h-100',

  template: () => Locale.translate('pay'),

  events: {
    click: 'onClicked',
  },

  onShow() {
    KeyboardModel.on('all', this.ensureButton, this);
    this.collection.on('all', this.ensureButton, this);
    this.ensureButton();
  },

  onDestroy() {
    KeyboardModel.off('all', this.ensureButton, this);
    this.collection.off('all', this.ensureButton, this);
  },

  ensureButton() {
    const totalPrice = this.collection.getTotalPriceWt();
    const totalPaid = this.collection.getTotalPaymentAgainstOrderWt();
    const keyboardModeLocking = KeyboardModel.isModeWithConfirmation();
    const isPayingAnything = parseFloat(totalPrice) > 0 && parseFloat(totalPaid) === 0;
    const disable = isPayingAnything || keyboardModeLocking;
    this.$el.attr('disabled', disable);
  },

  onClicked() {
    this.triggerMethod('pay:clicked');
  },

}));
