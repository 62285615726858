define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/list/layout.hbs',

  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/views/paymentMethods/list/row',

  'modules/common/components/locale',
], (
  $, _, Backbone, Template,
  PaymentMethodCollection, RowView,
  Locale,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childView: RowView,

  childViewOptions() {
    return {
      paymentMethodCollection: this.collection,
      columns: this.columns,
      shopManualRefundPopup: this.shopManualRefundPopup,
    };
  },

  childViewContainer: 'tbody',

  className: 'payment-methods-list designer-box rounded',

  initialize({
    collection = null, columns = null, shopManualRefundPopup = false,
  }) {
    this.collection = collection || PaymentMethodCollection;
    this.shopManualRefundPopup = shopManualRefundPopup;
    this.columns = columns || [
      'description',
      'ppu',
    ];
  },

  getHeaders() {
    const headers = [];

    this.columns.forEach((item) => {
      switch (item) {
        case 'description':
          headers.push({
            name: Locale.translate('description'),
            cls: '',
          });
          break;
        case 'ppu':
          headers.push({
            name: Locale.translate('value'),
            cls: 'text-right',
          });
          break;
        case 'delete':
          headers.push({
            name: '',
            cls: 'text-center',
          });
          break;
      }
    });

    return headers;
  },

  serializeData() {
    return {
      headers: this.getHeaders(),
    };
  },

}));
