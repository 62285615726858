define([
  'jquery',
  'backbone',
  'modules/shop.cash-register-retail/templates/payments/processingItem.hbs',

  'modules/shop.cash-register-retail/views/payments/qrOverlay.js',
  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/views/inputs/giftCardInput',

  'modules/common/components/moment',
  'modules/common/components/locale',
], (
  $, Backbone, Template,
  QrOverlayView, KeypadsView, GiftCardInputView,
  Moment, Locale,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'tbody',
  className: 'payments-processingItem',

  template: Template,

  ui: {
    timer: '[data-ui="timer"]',
    cancelButton: 'button[data-action="cancel"]',
    showLargeButton: 'button[data-action="show-large"]',
    submitPinButton: 'button[data-action="submit-pin"]',
  },

  regions: {
    keys: '[data-region="keys"]',
    pin: '[data-region="pin"]',
  },

  events: {
    'click @ui.cancelButton': 'cancelClicked',
    'click @ui.showLargeButton': 'showLargeClicked',
    'click @ui.submitPinButton': 'submitPinClicked',
  },

  modelEvents: {
    change: 'render',
  },

  initialize() {
    this.pinView = null;
  },

  onRender() {
    this.initializeTimer();
    this.renderSuccessCls();
    if (this.model.get('showPin')) {
      this.renderKeys();
      this.renderPin();
    }
  },

  onDestroy() {
    this.cleanTimer();
    this.cleanQrPopup();
  },

  renderKeys() {
    this.getRegion('keys').show(new KeypadsView());
  },

  renderPin() {
    this.pinView = new GiftCardInputView({
      autoSelect: true,
    });
    this.pinView.on('view:confirm', this.submitPin, this);
    this.getRegion('pin').show(this.pinView);
  },

  renderSuccessCls() {
    const success = this.model.get('success');
    if (typeof success === 'boolean') {
      if (success) {
        this.$el.addClass('success').removeClass('error');
      } else {
        this.$el.addClass('error').removeClass('success');
      }
    }
  },

  cancelClicked() {
    const def = new $.Deferred();

    this.ui.cancelButton.prop('disabled', true);
    this.model.trigger(
      'payment:cancel',
      def,
    );
    def.always(() => {
      if (!this.isDestroyed) {
        this.ui.cancelButton.prop('disabled', false);
      }
    });
  },

  showLargeClicked() {
    this.qrView = new QrOverlayView();
    this.qrView.open().always(() => { this.qrView = null; });
  },

  submitPinClicked() {
    return this.submitPin();
  },

  submitPin() {
    const def = new $.Deferred();
    const pin = this.pinView.getCurrentValue();

    this.ui.submitPinButton.prop('disabled', true);
    this.model.trigger(
      'payment:submit:pin',
      def,
      pin,
    );

    def.always(() => {
      if (!this.isDestroyed) {
        this.ui.submitPinButton.prop('disabled', false);
      }
    });
  },

  cleanQrPopup() {
    if (this.qrView) {
      this.qrView.close();
    }
  },

  initializeTimer() {
    this.cleanTimer(); // in case it`s re-rendered
    // render the timer
    const startTime = this.model.get('startTime');
    if (startTime) {
      const endTime = this.model.get('endTime');
      if (endTime) {
        if (this.model.get('success')) {
          // finished success -> show seconds
          this.renderTimerValue(this.getTimer(startTime, endTime));
        } else {
          // error -> show time of a day
          this.renderTimerValue(
            Locale.translate('at_{0}', new Moment(endTime).format('HH:mm')),
          );
        }
      } else {
        // running timer
        this.timer = setInterval(() => {
          this.renderTimerValue(this.getTimer(startTime));
        }, 100);
      }
    }
  },

  renderTimerValue(value) {
    this.ui.timer.text(value);
  },

  cleanTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  getTimer(startTime, endTime) {
    if (!endTime) {
      endTime = (new Date()).getTime();
    }
    let diff = endTime - startTime;
    diff /= 1000;
    diff = diff.toFixed(1);
    return Locale.translate('{0}s', diff); // time in secconds
  },

}));
