define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/list/cellDescription.hbs',

  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',

  'modules/common/components/currency',
  'modules/common/components/locale',
], (
  $, _, Backbone, Template,
  LoyaltyProgramModel,
  Currency, Locale,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'td',

  initialize({ paymentMethodCollection, loyaltyProgramModel = null }) {
    this.loyaltyProgramModel = loyaltyProgramModel || LoyaltyProgramModel;
    this.paymentMethodCollection = paymentMethodCollection;
  },

  getTitle() {
    const id = this.options.paymentModel.get('id');
    const title = this.options.paymentModel.get('title');
    if (
      id.startsWith(this.paymentMethodCollection.GIFTCARD_METHOD)
      || id.startsWith(this.paymentMethodCollection.EXTERNAL_GIFTCARD_METHOD)
    ) {
      const balance = this.options.paymentModel.get('balance');
      return `${title} (${Currency.format('EUR', balance)})`;
    }
    return title;
  },

  getDescription() {
    const id = this.options.paymentModel.get('id');
    if (
      id.startsWith(this.paymentMethodCollection.GIFTCARD_METHOD)
      || id.startsWith(this.paymentMethodCollection.EXTERNAL_GIFTCARD_METHOD)
    ) {
      return this.options.paymentModel.get('code');
    }

    if (id.startsWith(this.paymentMethodCollection.LOYALTY_POINTS_METHOD)) {
      const pointsPerCent = this.loyaltyProgramModel.get('points_per_cent_with_payment');
      return `${pointsPerCent} ${Locale.translate('points')} = ${Currency.format('EUR', '0.01')}`;
    }

    return null;
  },

  serializeData() {
    return {
      title: this.getTitle(),
      description: this.getDescription(),
    };
  },

}));
