define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/paymentMethods/list/cellDescription',
  'modules/shop.cash-register-retail/views/paymentMethods/list/cellPpu',
  'modules/shop.cash-register-retail/views/paymentMethods/list/cellPpuRefund.js',
  'modules/shop.cash-register-retail/views/paymentMethods/list/cellDelete',
], (
  $, _, Backbone,
  DescriptionView, PpuView, PpuRefundView, DeleteView,
) => Backbone.Marionette.CollectionView.extend({

  tagName: 'tr',

  getChildView(model) {
    switch (model.get('id')) {
      case 'description':
        return DescriptionView;
      case 'ppu':
        return this.model.get('refund_amount')
          ? PpuRefundView : PpuView; // show the not editable refund field
      default:
        return DeleteView;
    }
  },

  childViewOptions() {
    return {
      paymentMethodCollection: this.paymentMethodCollection,
      paymentModel: this.model,
      shopManualRefundPopup: this.shopManualRefundPopup,
    };
  },

  initialize({ paymentMethodCollection, shopManualRefundPopup, columns }) {
    this.paymentMethodCollection = paymentMethodCollection;
    this.collection = new Backbone.Collection();
    this.shopManualRefundPopup = shopManualRefundPopup;
    const self = this;
    columns.forEach((column) => {
      self.collection.add({ id: column });
    });
  },

}));
