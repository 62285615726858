define([
  'modules/common/views/swappable',
  './layout',
  'modules/shop.cash-register-retail/views/successScreens/invoice/layout',
], (
  SwappableView, PaymentView, SuccessView,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    const {
      model, customerModel, invoiceStatModel, loyaltyProgramModel,
    } = options;

    this.setMainView(PaymentView, {
      model,
      customerModel,
      invoiceStatModel,
      loyaltyProgramModel,
    });
    this.setView('success', SuccessView, {
      model,
      customerModel,
      loyaltyProgramModel,
    });
  },

}));
