define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentScreens/order/layout',

  'modules/shop.cash-register-retail/views/paymentMethods/available/swappable',
  'modules/shop.cash-register-retail/views/customers/selection/selected',
  './itemTotals',

  'modules/shop.cash-register-retail/views/paymentMethods/total',
  'modules/shop.cash-register-retail/views/paymentMethods/list/layout',
  './list',
  './payButton',

  'modules/shop.cash-register-retail/views/payments/processingCollection',
  'modules/shop.cash-register-retail/views/popups/paymentErrorPopup',
  'modules/shop.cash-register-retail/views/payments/manualRefundPopup',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/components/payment',
  'modules/common/components/locale',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/events/app/fullScreenLoader',

  'modules/shop.cash-register-retail/collections/paymentMethodItem',
  'modules/shop.cash-register-retail/components/order',
  'modules/shop.cash-register-retail/components/orderReceipt',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/components/loyaltyProgram',

  'modules/shop.cash-register-retail/views/payments/cashierDisplay',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
], (
  $, _, Backbone, Template,
  AvailablePaymentMethodsView, CustomerView, ItemTotalsView,
  TotalPaymentMethodsView, ListPaymentMethodsView, OrderListView, PayButton,
  ProcessingCollectionView, PaymentErrorPopup, RefundPopupView, KeypadView,
  Payment, Locale, Promisify, Toaster, CashRegisterApi,
  FullScreenLoaderEvent,
  PaymentMethodCollection, Order, OrderReceipt,
  KeyboardModel, HistoryBreadcrumb, Printing, LoyaltyProgramComponent,
  CashierDisplayView, PaymentMethods,
) => Backbone.Marionette.LayoutView.extend({

  className: 'payment-screen-order pos-page-content payment-screen-grid',

  template: Template,

  regions: {
    customer: '[data-region=customer]',
    paymentMethods: '[data-region=payment-methods]',
    payments: '[data-region=payments]',
    paymentTotals: '[data-region=payment-totals]',
    items: '[data-region=items]',
    itemTotals: '[data-region=item-totals]',
    keypad: '[data-region=keypad]',
    pay: '[data-region=pay]',
  },

  onRender() {
    this.renderCustomer();
    this.renderPayments();
    this.renderPaymentMethods();
    this.renderPaymentTotals();
    this.renderItems();
    this.renderItemTotals();
    this.renderKeypad();
    this.renderPay();
  },

  initialize({ customerModel, backRoute = null }) {
    this.paymentMethodCollection = new PaymentMethodCollection();
    this.paymentMethodCollection.setTotalPriceWt(this.model.get('value_outstanding_wt'));

    this.customerModel = customerModel;
    this.logCollection = new Backbone.Collection();
    this.backRoute = backRoute;
  },

  renderCustomer() {
    const view = new CustomerView({
      model: this.customerModel,
      allowChange: false,
    });
    const region = this.getRegion('customer');
    region.show(view);
  },

  renderPayments() {
    const view = new ListPaymentMethodsView({
      collection: this.paymentMethodCollection,
      shopManualRefundPopup: () => this.shopManualRefundPopup(),
    });
    const region = this.getRegion('payments');
    region.show(view);
  },

  renderPaymentMethods() {
    const view = new AvailablePaymentMethodsView({
      customerModel: this.customerModel,
      collection: this.paymentMethodCollection,
      totalPriceWt: this.model.get('value_outstanding_wt'),
    });
    const region = this.getRegion('paymentMethods');
    region.show(view);
  },

  renderPaymentTotals() {
    const view = new TotalPaymentMethodsView({
      totalPriceWt: this.model.get('value_outstanding_wt'),
      collection: this.paymentMethodCollection,
    });
    const region = this.getRegion('paymentTotals');
    region.show(view);
  },

  renderItems() {
    const view = new OrderListView({
      model: this.model,
    });
    const region = this.getRegion('items');
    region.show(view);
  },

  renderItemTotals() {
    const view = new ItemTotalsView({
      model: this.model,
      backRoute: this.backRoute,
    });
    const region = this.getRegion('itemTotals');
    region.show(view);
  },

  renderKeypad() {
    const view = new KeypadView();
    const region = this.getRegion('keypad');
    region.show(view);
  },

  renderPay() {
    const view = new PayButton({
      model: this.model,
      collection: this.paymentMethodCollection,
    });
    const region = this.getRegion('pay');
    region.show(view);
  },

  childEvents: {
    'pay:clicked': 'payClicked',
  },

  async payClicked() {
    const fullScreenLoaderDeferred = new $.Deferred();
    try {
      const paymentResults = await this.processPaymentMethods(fullScreenLoaderDeferred);
      await this.finalize(paymentResults);
    } catch (error) {
      fullScreenLoaderDeferred.reject();
      console.error(error);
    }
  },

  async processPaymentMethods(fullScreenLoaderDeferred) {
    this.logCollection = new Backbone.Collection();
    const processingView = new ProcessingCollectionView({
      collection: this.logCollection,
      number: this.model.get('number'),
      type: ProcessingCollectionView.TYPE_OUTSTANDING_ORDER,
    });
    const cashierDisplay = new CashierDisplayView();

    const event = new FullScreenLoaderEvent({
      deferred: fullScreenLoaderDeferred,
      statusView: processingView,
      cashierDisplay,
      title: Locale.translate('processing_order_{number}', { number: this.model.get('number') }),
      extraClassName: 'payments-processing',
    });
    event.trigger();

    const log = processingView.full(this.model);

    let relation_data_id = null;
    if (!this.model.get('is_anonymous')) {
      relation_data_id = this.model.get('relation_data_id');
    }
    let paymentResults = null;
    try {
      paymentResults = await Payment.processPaymentMethods({
        paymentMethodCollection: this.paymentMethodCollection,
        totalValueWt: this.model.get('value_outstanding_wt'),
        processingView,
        cashierDisplay,
        paymentProducts: Payment.getPaymentProducts(this.model.getOrderItemCollection()),
        relation_data_id,
        orderModel: this.model,
      });
      await Payment.attachOrderPayments({
        paymentResults,
        processingView,
        orderId: this.model.get('id'),
      });

      log.success();
    } catch (error) {
      log.error(error.error);
      fullScreenLoaderDeferred.reject(error);

      Payment.processFailedOrderPayments({
        error,
        processingView,
        orderId: this.model.get('id'),
        number: this.model.get('number'),
        paymentMethodCollection: this.paymentMethodCollection,
      });

      processingView.stopAllTimes();

      throw error;
    }

    await Payment.processOrderSignature(
      this.model, processingView, paymentResults, this.paymentMethodCollection,
    );

    fullScreenLoaderDeferred.resolve();

    return paymentResults;
  },

  async finalize(paymentResults) {
    const loyaltyPaymentData = LoyaltyProgramComponent.getLoyaltyPaymentData(
      this.model,
      this.paymentMethodCollection.getLoyaltyPointsPaymentModel(),
      this.loyaltyProgramModel,
      this.customerModel,
    );

    const options = {};
    if (loyaltyPaymentData !== null) {
      // Need this to print loyalty points on receipt
      loyaltyPaymentData.hasLoyaltyPoints = true;
      options.loyaltyPaymentData = loyaltyPaymentData;
    }

    const receiptData = Printing.createReceiptJson(
      this.model,
      this.paymentMethodCollection,
      options,
    );
    this.model.set('order_vars', [{
      alias: 'receipt_escpos',
      value: receiptData,
    }]);

    let hasCCVPayment = false;
    const pinPayment = Payment.getPaymentResultByMethod(paymentResults, PaymentMethods.PIN_METHOD);
    if (pinPayment) {
      const paymentMethod = pinPayment.get('paymentMethod');
      if (!pinPayment.get('error') && paymentMethod && paymentMethod.isCCVPayment) {
        // CCV pin receipts are required to be printed immediately
        hasCCVPayment = true;
        Printing.printReceipt(this.model, options).fail(() => {
          Toaster.error(Locale.translate('could_not_print_receipt'));
        });
      }
    }

    setTimeout(() => {
      CashRegisterApi.logAction('ORDER_CREATED', {
        order_id: this.model.get('id'),
      });

      // Saving stuff that is not important now.
      // So we can save it async.
      OrderReceipt.saveOrderReceiptJob(this.model.get('id'), receiptData);
    });

    // clean the protector, so we can always leave the page
    window.onbeforeunload = null;
    // pop the payment route from stack, so next time the goBack is called if goes to correct route
    const backRoute = HistoryBreadcrumb.getBackRoute();
    this.triggerMethod('layout:swap', 'success', {
      logCollection: this.logCollection,
      backRoute: this.backRoute || backRoute,
      paymentMethodCollection: this.paymentMethodCollection,
      hasCCVPayment,
    });
  },

  onShow() {
    KeyboardModel.resetMode();
    this.addRefundCashPayment();

    window.onbeforeunload = () => this.pageProtector();
  },

  addRefundCashPayment() {
    const amount = this.model.get('value_outstanding_wt');
    if (parseFloat(amount) <= 0) {
      this.paymentMethodCollection.addMethodById(
        this.paymentMethodCollection.CASH_METHOD,
        { totalPriceWt: amount },
      );
    }
  },

  shopManualRefundPopup() {
    const view = new RefundPopupView({
      orderId: this.model.get('id'),
      paymentMethodCollection: this.paymentMethodCollection,
    });
    view.open();
  },

  pageProtector() {
    const hasPaid = Payment.getTotalWtOfLockedItems(this.paymentMethodCollection) !== '0.00';
    if (hasPaid) {
      this.shopManualRefundPopup();
      return true;
    }
    return null;
  },

  onDestroy() {
    // clean protectors
    window.onbeforeunload = null;
  },

}));
