define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/total',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',

  'modules/common/components/currency',
], (
  $, _, Backbone, Template,
  OrderItemCollection, PaymentMethodItemCollection,
  Currency,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'block designer-box rounded h-100',

  initialize({ collection = null, totalPriceWt = null }) {
    this.collection = collection || PaymentMethodItemCollection;
    this.totalPriceWt = _.isNull(totalPriceWt) ? this.collection.getTotalPriceWt() : totalPriceWt;
  },

  onShow() {
    this.collection.on('all', this.renderLater, this);
    // Ignore orderItemCollection updates because the totalPriceWt is already passed.
    if (!this.totalPriceWt) OrderItemCollection.on('all', this.renderLater, this);
  },

  onDestroy() {
    this.collection.off('all', this.renderLater, this);
    // Ignore orderItemCollection updates because the totalPriceWt is already passed.
    if (!this.totalPriceWt) OrderItemCollection.off('all', this.renderLater, this);
  },

  getCurrency(value) {
    return Currency.format('EUR', Currency.toCurrency(value));
  },

  renderLater: _.debounce(function () {
    // When editing edit field but not clicking confirm, and clicking on pay in the checkout overview.
    // It causes an update. and causes this to render after its been destroyed.
    if (!this.isDestroyed) {
      this.render();
    }
  }, 10),

  getTotalPriceWt() {
    if (_.isUndefined(this.totalPriceWt)) {
      return OrderItemCollection.getTotalPriceWt();
    }
    return this.totalPriceWt;
  },

  serializeData() {
    const total = this.getTotalPriceWt();
    const toPay = this.collection.getLeftToPayWt();
    const spareChange = this.collection.getSpareChangeWt();

    const isNegative = total < 0;
    const returnedByCash = this.collection.getReturnedByCash();
    const returnedByPin = this.collection.getReturnedByPin();
    const returnedByGiftCard = this.collection.getReturnedByGiftCard();
    const returnedByOther = this.collection.getReturnedByOther();

    const isReturnedByCash = parseFloat(returnedByCash) !== 0.0;
    const isReturnedByPin = parseFloat(returnedByPin) !== 0.0;
    const isReturnedByGiftCard = parseFloat(returnedByGiftCard) !== 0.0;
    const isReturnedByOther = parseFloat(returnedByOther) !== 0.0;

    return {
      total: this.getCurrency(total),
      totalCls: '',

      toPay: this.getCurrency(toPay),
      toPayCls: parseFloat(toPay) < 0.00 ? 'text-success' : 'text-danger',

      spareChange: this.getCurrency(spareChange),
      spareChangeCls: '',

      isNegative,
      returnedByCash: this.getCurrency(returnedByCash),
      returnedByPin: this.getCurrency(returnedByPin),
      isReturnedByCash,
      isReturnedByPin,
      isReturnedByGiftCard,
      returnedByGiftCard: this.getCurrency(returnedByGiftCard),

      isReturnedByOther,
      returnedByOther: this.getCurrency(returnedByOther),
    };
  },

}));
