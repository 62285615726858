define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/payments/cashierDisplay.hbs',
  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template, Loader,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  ui: {
    line1: '[data-ui="line1"]',
    line2: '[data-ui="line2"]',
    cancelButton: '[data-ui="cancel"]',
    cashierDisplay: '[data-ui="cashier-display"]',
  },

  events: {
    'click [data-action="cancel"]': 'cancelButton',
  },

  initialize() {
    this.shown = false;
    this.cancelButtonShown = false;
    this.line1 = '';
    this.line2 = '';
  },

  onRender() {
    this.ui.cashierDisplay.toggleClass('hidden', !this.shown);
    this.ui.cancelButton.toggle(this.cancelButtonShown);
    this.ui.line1.text(this.line1);
    this.ui.line2.text(this.line2);

    if (this.cancelDef && this.cancelDef.state() === 'pending') {
      this.loader.showLoader('cancel-button');
    } else {
      this.loader.hideLoader('cancel-button');
    }
  },

  updateDisplay(line1, line2) {
    this.line1 = line1;
    this.line2 = line2;
    this.render();
  },

  toggleCancelButton(show) {
    this.cancelButtonShown = show;
    this.render();
  },

  showDisplay(show) {
    this.shown = show;
    this.render();
  },

  onCancel(callback) {
    this.on('cancel:click', () => {
      this.cancelDef = callback();
      this.cancelDef.always(() => {
        this.render();
      });
      this.render();
    });
  },

  cancelButton() {
    this.trigger('cancel:click');
  },
}));
